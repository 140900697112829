import Middleware from './middleware'

Middleware.apolloReset = function ({ app }) {
  const reset = async () => {
    app.i18n.__localeSwitched = false
    await app.$graphql.resetStore()
  }

  if (app.i18n && app.i18n.__localeSwitched) {
    return reset()
  } else {
    return null
  }
}
