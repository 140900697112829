export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const FeatureTag = () => import('../../components/FeatureTag.vue' /* webpackChunkName: "components/feature-tag" */).then(c => wrapFunctional(c.default || c))
export const LoginLink = () => import('../../components/LoginLink.vue' /* webpackChunkName: "components/login-link" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LayoutCopyright = () => import('../../components/layout/Copyright.vue' /* webpackChunkName: "components/layout-copyright" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavMenu = () => import('../../components/layout/NavMenu.vue' /* webpackChunkName: "components/layout-nav-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutTopBar = () => import('../../components/layout/TopBar.vue' /* webpackChunkName: "components/layout-top-bar" */).then(c => wrapFunctional(c.default || c))
export const SectionClients = () => import('../../components/section/Clients.vue' /* webpackChunkName: "components/section-clients" */).then(c => wrapFunctional(c.default || c))
export const SectionHeader = () => import('../../components/section/Header.vue' /* webpackChunkName: "components/section-header" */).then(c => wrapFunctional(c.default || c))
export const SectionRegister = () => import('../../components/section/Register.vue' /* webpackChunkName: "components/section-register" */).then(c => wrapFunctional(c.default || c))
export const SectionTech = () => import('../../components/section/Tech.vue' /* webpackChunkName: "components/section-tech" */).then(c => wrapFunctional(c.default || c))
export const RegisterButton = () => import('../../components/register/Button.vue' /* webpackChunkName: "components/register-button" */).then(c => wrapFunctional(c.default || c))
export const RegisterForm = () => import('../../components/register/Form.vue' /* webpackChunkName: "components/register-form" */).then(c => wrapFunctional(c.default || c))
export const ShopFeaturesTabs = () => import('../../components/shop/FeaturesTabs.vue' /* webpackChunkName: "components/shop-features-tabs" */).then(c => wrapFunctional(c.default || c))
export const ShopPricingPlan = () => import('../../components/shop/PricingPlan.vue' /* webpackChunkName: "components/shop-pricing-plan" */).then(c => wrapFunctional(c.default || c))
export const ShopPricingTable = () => import('../../components/shop/PricingTable.vue' /* webpackChunkName: "components/shop-pricing-table" */).then(c => wrapFunctional(c.default || c))
export const ShopSliderDesktop = () => import('../../components/shop/SliderDesktop.vue' /* webpackChunkName: "components/shop-slider-desktop" */).then(c => wrapFunctional(c.default || c))
export const ShopSliderMobile = () => import('../../components/shop/SliderMobile.vue' /* webpackChunkName: "components/shop-slider-mobile" */).then(c => wrapFunctional(c.default || c))
export const SectionShopFeatures = () => import('../../components/section/shop/Features.vue' /* webpackChunkName: "components/section-shop-features" */).then(c => wrapFunctional(c.default || c))
export const SectionShopHeader = () => import('../../components/section/shop/Header.vue' /* webpackChunkName: "components/section-shop-header" */).then(c => wrapFunctional(c.default || c))
export const SectionShopPricing = () => import('../../components/section/shop/Pricing.vue' /* webpackChunkName: "components/section-shop-pricing" */).then(c => wrapFunctional(c.default || c))
export const SectionShopSlider = () => import('../../components/section/shop/Slider.vue' /* webpackChunkName: "components/section-shop-slider" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
