
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  vessel: {
    page: {
      height: 'full',
      width: 'full',
      textAlign: 'center',
      padding: 'lg',
      display: 'table',
      minHeight: 'screen'
    },
    wrapper: {
      display: 'table-cell',
      verticalAlign: 'middle'
    },
    section: {
      marginY: 'md'
    },
    link: {
      color: 'primary',
      display: 'inline-block',
      marginTop: 'md',
      paddingY: 'xs',
      paddingX: 'sm'
    }
  },
  head() {
    return {
      title: this.title,
      meta: [{
        hid: 'prerender-status-code',
        name: 'prerender-status-code',
        content: this.statusCode
      }]
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    oldBrowser() {
      if (typeof window != 'undefined') {
        return window.oldBrowser == true
      } else {
        return false
      }
    },
    messages() {
      const keys = { 500: 'general', 404: 'notFound' }
      const data = Object(this.$t('site.errors'))
      const name = this.oldBrowser ? 'oldBrowser' : keys[this.statusCode]

      return data[name]
    },
    showLink() {
      const route = this.$route.name || ''
      return !this.oldBrowser && !route.startsWith('index')
    }
  }
}
