import { make } from 'vuex-pathify'

const register = {
  name: null,
  email: null,
  phone: null,
  website: null,
  solution: 'newEshop',
  plan: 'Business'
}

export const state = () => ({
  registerForm: { ...register }
})

export const getters = make.getters(state)

export const mutations = {
  RESET_REGISTER_FORM(state) {
    state.registerForm = { ...register }
  },
  ...make.mutations(state)
}

export const actions = {
  resetRegisterForm({ commit }) {
    commit('RESET_REGISTER_FORM')
  },
  ...make.actions(state)
}
