
export default {
  head() {
    return this.$seoMeta.head(this, {
      siteName: this.$t('siteName')
    })
  },
  vessel: {
    container: {
      backgroundColor: 'white',
      padding: 'md',
      marginY: 'md'
    },
    link: {
      textColor: 'primary',
      textDecoration: 'underline'
    }
  }
}
