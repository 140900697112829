import { scrollTo } from 'scroll-js'

export default (ctx, inject) => {
  const scrollToEl = async function(target, offset) {
    const el = document.getElementById(target)

    if (el) {
      const top = el.offsetTop - (offset || 150)
      await scrollTo(window, { top: top, behavior: 'smooth' })
    }
  }

  inject('scrollTo', scrollToEl)
}
