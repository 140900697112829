import { isArray, isPlainObject, isBlank, reduce } from 'vessel/utils'

function parseStaticMeta(value, { seoMeta, hostname, sitename }) {
  const titleFallback = (key) => {
    const fallback = seoMeta && seoMeta.title || ''
    return value[key] && value[key].title || value.title || fallback
  }

  const imageFallback = (key) => {
    const fallback = seoMeta && seoMeta.image || '/icon.png'
    const image = value[key] && value[key].image || value.image || fallback

    return image.startsWith('http') ? image : `${hostname}${image}`
  }

  const descriptionFallback = () => {
    const description = value.description
      || seoMeta && seoMeta.description
      || ''

    return description.substring(0, 160)
  }

  const defaults = {
    title: value.title || seoMeta && seoMeta.title,
    meta: [
      {
        name: 'description',
        content: descriptionFallback(),
        hid: 'description'
      },
      {
        property: 'og:title',
        content: titleFallback('og'),
        hid: 'og:title'
      },
      {
        property: 'og:description',
        content: descriptionFallback(),
        hid: 'og:description'
      },
      {
        property: 'og:image',
        content: imageFallback('og'),
        hid: 'og:image'
      },
      {
        property: 'twitter:title',
        content: titleFallback('twitter'),
        hid: 'twitter:title'
      },
      {
        property: 'twitter:description',
        content: descriptionFallback(),
        hid: 'twitter:description'
      },
      {
        property: 'twitter:card',
        content: value.twitter && value.twitter.card || 'summary',
        hid: 'twitter:card'
      },
      {
        property: 'twitter:image',
        content: imageFallback('twitter'),
        hid: 'twitter:image'
      }
    ]
  }

  let robots = value.robots || []

  if (value.index == false) {
    robots.push('noindex', 'nofollow')
  }

  robots = Array.from(new Set(robots))

  if (robots.length) {
    defaults.meta.push({
      name: 'robots',
      content: robots.join(', '),
      hid: 'robots'
    })
  }

  if (value.searchbox) {
    const target = value.searchbox == true
      ? 'products?search={term}'
      : value.searchbox.target

    defaults.script = [
      {
        type: 'application/ld+json',
        hid: 'searchbox',
        json: {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": sitename,
          "url": hostname,
          "potentialAction": {
            "@type": "SearchAction",
            "target": `${hostname}/${target}`,
            "query-input": "required name=term"
          }
        }
      }
    ]
  }

  if (value.titleTemplate) {
    defaults.titleTemplate = value.titleTemplate
  }

  return defaults
}

const mergeMeta = (...items) => {
  const object = {}

  items.forEach(item => {
    Object.keys(item).forEach(key => {
      const value = object[key]

      if (isArray(value)) {
        object[key] = [...value, ...item[key]]
      } else if (isPlainObject(value)) {
        object[key] = { ...value, ...item[key] }
      } else {
        object[key] = item[key]
      }
    })
  })

  return object
}

export default function ({ app, store }, inject) {
  const options = {"seoMeta":{"image":"\u002Fseo-image.png"},"seo":undefined,"hostname":"https:\u002F\u002Fwww.statica.eu","sitename":"Statica"}

  inject('seoMeta', {
    meta: {},
    params: {},
    update(value) {
      this.meta = value
    },
    reset() {
      this.meta = {}
    },
    head(component, { siteName, siteDesc, ...headMeta }) {
      const i18n = component.$nuxtI18nHead({ addSeoAttributes: true })
      const meta = mergeMeta(i18n, this.meta, headMeta)

      const { reverseIndexTitle } = options.seo || {}

      if (reverseIndexTitle !== false && component.$route.name.startsWith('index')) {
        meta.titleTemplate = `${siteName} | ${siteDesc}`
      } else {
        meta.titleTemplate = `%s | ${siteName}`
      }

      return meta
    },
    page(staticMeta) {
      return parseStaticMeta(staticMeta, options)
    },
    async setParams(data) {
      this.params = reduce(data, (params, code, id) => params[code] = { id })
    },
    async resetParams() {
      this.params = {}
    }
  })
}
