import Middleware  from './middleware'

Middleware.oldBrowser = function (ctx) {
  const Detector = {
    get isMSIE() {
      const msie = window.navigator.userAgent.indexOf('MSIE ')
      return msie > 0
    },
    get isTrident() {
      const trident = window.navigator.userAgent.indexOf('Trident/')
      return trident > 0
    },
    detect() {
      if (this.isMSIE || this.isTrident) {
        window.oldBrowser = true
        ctx.error({ statusCode: 417, message: 'Unsupported browser' })
      }
    }
  }

  if (process.client) {
    Detector.detect()
  }
}
