
export default {
  inheritAttrs: false,
  props: {
    button: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    linkAttrs() {
      return {
        href: 'https://admin.statica.eu',
        target: this.blank ? '_blank' : undefined
      }
    }
  }
}
