export default function({ app }) {
  if (app.i18n) {
    app.i18n.onLanguageSwitched = async (oldLocale, newLocale) => {
      for (const key in app.i18n) {
        if (key.startsWith('__switch')) {
          const callback = app.i18n[key]

          if (typeof callback === 'function') {
            try {
              await callback.call(null, oldLocale, newLocale)
            } catch (e) {
              console.warn(`I18n callback error: ${key}`)
              console.error(e)
            }
          }
        }
      }

      app.i18n.__localeSwitched = true
    }
  }
}
