
export default {
  head() {
    return this.$seoMeta.head(this, {
      siteName: this.$t('siteName'),
      siteDesc: this.$t('metaTitle')
    })
  },
  vessel: {
    topbar: {
      paddingX: 'sm',
      paddingY: 'sm',
      backgroundColor: 'white'
    },
    footer: {
      backgroundColor: 'grey-lighter',
      textColor: 'black',
      overflow: 'hidden'
    }
  },
  data() {
    return {
      stuck: false,
      sticky: true
    }
  },
  computed: {
    border() {
      return this.stuck ? true : 'transparent'
    }
  },
  methods: {
    onStick() {
      this.stuck = true
    },
    onUnstick() {
      this.stuck = false
    }
  }
}
