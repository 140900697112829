
export default {
  props: {
    mode: {
      type: String,
      default: 'horizontal'
    }
  },
  vessel: {
    menuItem: {
      fontSize: 'sm',
      cursor: 'pointer',
      fontWeight: 'semibold',
      $vertical: {
        padding(value) {
          return value ? 'sm' : null
        }
      },
      hover: {
        color: 'primary',
        backgroundColor() {
          return this.vertical ? 'grey-lightest' : null
        }
      }
    }
  },
  computed: {
    vertical() {
      return this.mode == 'vertical'
    },
    direction() {
      return this.vertical ? 'col' : 'row'
    },
    items() {
      return [
        {
          label: this.$t('shop.menu.solutions'),
          target: 'solutions'
        },
        {
          label: this.$t('shop.menu.features'),
          target: 'features'
        },
        {
          label: this.$t('shop.menu.pricing'),
          target: 'pricing'
        },
      ]
    }
  },
  methods: {
    onClick(target) {
      const offset = this.$screen.xl ? 150 : 120

      this.$scrollTo(target, offset)
      this.$drawer.close('mobile-menu')
    }
  }
}
