import { isFunction } from 'vessel/utils'

class Tracker {
  constructor(ctx) {
    this.ctx       = ctx
    this.providers = {}
  }

  get enabled() {
    return Object.values(this.providers).some(item => !!item.options.enabled)
  }

  get active() {
    return Object.keys(this.providers).length > 0
  }

  get consent() {
    return this.ctx.store.get('site/cookieConsent')
  }

  get marketing() {
    return this.ctx.store.get('site/marketingCookies')
  }

  get userEnabled() {
    return this.marketing == true
  }

  get showControls() {
    return this.active && this.consent !== true
  }

  isEnabled(provider) {
    let track = false

    if (!this.consent && !this.marketing) {
      track = provider.options.enabled
    } else {
      track = this.userEnabled
    }

    return track
  }

  register(name, provider) {
    this.providers[name] = provider

    provider.enabled = this.isEnabled(provider)
    provider.init()
  }

  query(method, ...args) {
    Object.values(this.providers).forEach(provider => {
      const callback = provider[method]

      if (callback && isFunction(callback)) {
        callback.call(provider, ...args)
      }
    })
  }

  optIn(trackPage = true) {
    const page = this._parseRoute(this.ctx.route)

    this.ctx.store.set('site/cookieConsent', true)
    this.ctx.store.set('site/marketingCookies', true)

    Object.values(this.providers).forEach(provider => {
      const disabled = provider.enabled == false
      provider.optIn()

      if (disabled && trackPage) {
        provider.trackPage(page)
      }
    })
  }

  optOut() {
    this.ctx.store.set('site/cookieConsent', true)
    this.ctx.store.set('site/marketingCookies', false)

    this.query('optOut')
  }

  trackPage(route) {
    const page = this._parseRoute(route)
    this.query('trackPage', page)
  }

  trackEvent(event, data = {}, ...args) {
    this.query('trackEvent', event, data, ...args)
  }

  trackPurchase(order) {
    if (!order) return

    const { number, total, currency, taxTotal, shipmentTotal, orderItems } = order
    const items = orderItems.map(this._parseOrderItem)

    this.query('trackPurchase', {
      transaction_id: number,
      value:          total,
      currency:       currency,
      tax:            taxTotal,
      shipping:       shipmentTotal,
      items:          items
    })
  }

  _parseOrderItem(item) {
    const { variant, product, quantity, price } = item
    const { sku, name, label } = variant

    const brand      = product.brand || { name: '' }
    const categories = product.categories || []
    const category   = categories.map(({ name }) => name).join('/')
    const varName    = label !== name ? label : undefined

    return {
      id:       sku,
      name:     name,
      brand:    brand.name,
      category: category,
      quantity: quantity,
      price:    price,
      variant:  varName
    }
  }

  _parseRoute(route) {
    const parseUri = uri => uri == '/' ? uri : uri.replace(/\/$/, '')

    return {
      path: parseUri(route.path),
      title: this.ctx.$seoMeta.meta.title
    }
  }
}

export default (ctx, inject) => {
  const instance = new Tracker(ctx)

  if (ctx.app && ctx.app.router) {
    ctx.app.router.afterEach(route => {
      instance.trackPage(route)
    })
  }

  inject('tracker', instance)
}
