import Middleware from './middleware'
import Permalink  from '/opt/build/repo/node_modules/@evince/site/lib/graphql/queries/Permalink.gql'

import { hasProp, isString } from 'vessel/utils'

Middleware.seoMeta = function (ctx) {
  const { app, params, route, store } = ctx

  const meta = route.meta.find(i => hasProp('query', i))
  const seo  = route.meta.find(i => hasProp('seo', i))

  const updateMeta = async () => {
    let data = null

    try {
      const result = await app.$graphql.query({
        query: Permalink,
        clientId: meta.query.client,
        locale: store.$i18n.locale,
        variables: {
          where: {
            slug: params.id,
            type: meta.query.type
          }
        }
      })

      data = result.data
      app.$seoMeta.setParams(data.translations)
    } catch (e) {
      app.$seoMeta.reset()
      app.router.app.error({ statusCode: 404, message: 'Not Found' })

      return
    }

    if (data) {
      const parsedMeta = data.seoMeta.meta.map((item) => {
        const hid = item.property || item.name || 'meta:property'

        if (!hid.startsWith('og:image')) {
          item.hid = hid
        }

        return item
      })

      const recordMeta = { ...data.seoMeta, meta: parsedMeta}
      app.$seoMeta.update(recordMeta)

      if (data.redirectTo) {
        app.i18n.__seoRedirect = true
        ctx.redirect({ name: ctx.route.name, params: { id: data.redirectTo } })
      }
    } else {
      app.$seoMeta.reset()
    }
  }

  const updateSeo = async () => {
    const metaData = seo.seo

    if (metaData) {
      const localized = Object.entries(metaData).reduce((result, [key, value]) => {
        result[key] = isString(value) ? app.i18n.t(value) : value
        return result
      }, {})

      const staticMeta = app.$seoMeta.page(localized)

      app.$seoMeta.update(staticMeta)
    } else {
      app.$seoMeta.reset()
    }
  }

  if (app.i18n.__seoRedirect) {
    app.i18n.__seoRedirect = false
    return null
  }

  app.$seoMeta.resetParams()

  if (meta) {
    return updateMeta()
  } else if (seo) {
    return updateSeo()
  } else {
    app.$seoMeta.reset()
    return null
  }
}
