import { isObject } from 'vessel/utils'

export default function({ app, i18n }) {
  const localePath = (route, locale) => {
    let path = isObject(route) ? route.path : route

    if (locale != false) {
      const lang = locale == true ? i18n.locale : locale
      return app.$pagePath(path, lang)
    } else {
      return path
    }
  }

  const routePath = (pagePath, locale) => {
    const route = app.router.currentRoute
    const path  = localePath(pagePath || route.path, locale)

    return path == '/' ? '/index' : path
  }

  if (app.$content) {
    app.$content.data = (dataPath, locale) => {
      const lang = locale == true ? i18n.locale : locale
      const path = `/data/${locale ? lang : dataPath}`

      return app.$content(path)
    }

    app.$content.page = (pagePath, locale) => {
      const path = routePath(pagePath, locale)
      return app.$content(path)
    }

    app.$content.fetchPage = async (pagePath, locale) => {
      const path = routePath(pagePath, locale)
      const [page] = await app.$content({ deep: true }).where({ path }).fetch()

      const meta = app.$seoMeta.page(page.seo || {})
      app.$seoMeta.update(meta)

      return page
    }
  }
}
